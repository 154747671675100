import {
  Breadcrumb,
  Button,
  Card,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Select,
  Spin,
} from "antd"
import { gql } from "apollo-boost"
import { Link } from "gatsby"
import React from "react"
import { Mutation, Query } from "react-apollo"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const { Option } = Select

const formItemLayoutWithLabel = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
}

const formItemLayoutWithoutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

const CREATE_ITEM = gql`
  mutation createItem($objects: [mbt_items_insert_input!]!) {
    insert_mbt_items(objects: $objects) {
      returning {
        category {
          name
        }
        code
        name
        unit
        initial_stock
        current_stock
      }
    }
  }
`

const LIST_ITEM_CATEGORIES = gql`
  query listItemCategories {
    mbt_item_categories(order_by: { name: asc }) {
      id
      name
    }
  }
`

const ItemForm = Form.create()(({ form }) => (
  <Mutation mutation={CREATE_ITEM}>
    {(createItem, { loading }) => (
      <Form
        {...formItemLayoutWithLabel}
        onSubmit={e => {
          e.preventDefault()
          form.validateFieldsAndScroll(async (errors, objects) => {
            if (!errors) {
              try {
                await createItem({
                  variables: { objects: [objects] },
                })
                form.resetFields()
                message.success("Successfully added new item")
              } catch (err) {
                message.error("Failed to add new item")
              }
            }
          })
        }}
      >
        <Query query={LIST_ITEM_CATEGORIES}>
          {({ data, loading: queryLoading }) => (
            <Form.Item label="Category">
              {form.getFieldDecorator("category_id", {
                rules: [
                  {
                    required: true,
                    message: "Category is required",
                  },
                ],
              })(
                <Select
                  disabled={loading}
                  notFoundContent={queryLoading ? <Spin size="small" /> : null}
                  optionFilterProp="children"
                  showSearch
                >
                  {(data.mbt_item_categories || []).map(({ id, name }) => (
                    <Option key={id}>{name}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}
        </Query>
        <Form.Item label="Code">
          {form.getFieldDecorator("code", {
            rules: [
              { required: true, message: "Code is required", whitespace: true },
            ],
          })(<Input disabled={loading} />)}
        </Form.Item>
        <Form.Item label="Name">
          {form.getFieldDecorator("name", {
            rules: [
              { required: true, message: "Name is required", whitespace: true },
            ],
          })(<Input disabled={loading} />)}
        </Form.Item>
        <Form.Item label="Unit">
          {form.getFieldDecorator("unit", {
            rules: [{ required: true, message: "Unit is required" }],
          })(
            <Select disabled={loading}>
              <Option value="klg">klg</Option>
              <Option value="pck">pck</Option>
              <Option value="pcs">pcs</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Initial stock">
          {form.getFieldDecorator("initial_stock", {
            initialValue: 0,
            rules: [{ type: "integer" }],
          })(
            <InputNumber
              disabled={loading}
              min={0}
              style={{ width: "100%" }}
              onChange={current_stock => form.setFieldsValue({ current_stock })}
            />
          )}
        </Form.Item>
        <Form.Item label="Current stock">
          {form.getFieldDecorator("current_stock", {
            initialValue: 0,
            rules: [{ type: "integer" }],
          })(
            <InputNumber
              disabled={loading}
              min={0}
              style={{ width: "100%" }}
              onChange={initial_stock => form.setFieldsValue({ initial_stock })}
            />
          )}
        </Form.Item>
        <Form.Item {...formItemLayoutWithoutLabel}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!form.isFieldsTouched()}
            loading={loading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    )}
  </Mutation>
))

export default () => (
  <Layout>
    <SEO title="Item" />
    <Breadcrumb style={{ margin: "16px 0" }}>
      <Breadcrumb.Item>
        <Link to="/">
          <Icon type="home" />
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="/item/">Item</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>New</Breadcrumb.Item>
    </Breadcrumb>
    <Card>
      <ItemForm />
    </Card>
  </Layout>
)
